.mainBanner {
    background-size: cover;
    position: relative;

    &.innerPage {
        padding: 13px 16px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: normal;
        opacity: 0.3;
    }

    .container {
        flex-direction: column;
        color: #fff;
        position: relative;
        z-index: 1;
        text-align: center;
        justify-content: center;
    }

    h1 {
        font-weight: 700;
        font-size: 42px;
        line-height: 49px;
        margin-bottom: 5px;

        @media (max-width: 786px) {
            font-size: 30px;
            line-height: 35px;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: 786px) {
        background: transparent;
        padding: 0;

        &:after {
            display: none;
        }

        &.innerPage {
            background: url(../img/bannerBgImg.png) no-repeat center center;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(262.84deg, #662980 14.11%, #03437a 87.33%);
                mix-blend-mode: normal;
                opacity: 0.3;
                display: block;
            }
        }

        .mbTopbanner {
            padding: 20px;
            background: url(../img/bannerBgImg.png) no-repeat center center;
            background-size: cover;
            position: relative;

            >* {
                position: relative;
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(262.84deg, #662980 14.11%, #03437a 87.33%);
                mix-blend-mode: normal;
                opacity: 0.3;
            }
        }
    }

    @media (max-width: 600px) {
        .mbTopbanner {
            background: url(../img/bannerBgImg.png) no-repeat center right -70vw;
        }
    }
}

.mainBanner1 {
    background-size: cover;
    position: relative;

    &.innerPage {
        padding: 13px 16px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: normal;
        opacity: 0.3;
    }

    .container {
        flex-direction: column;
        color: #fff;
        position: relative;
        text-align: center;
        justify-content: center;
    }

    @media (max-width: 786px) {
        background: transparent;
        padding: 0;

        &:after {
            display: none;
        }

        &.innerPage {

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                mix-blend-mode: normal;
                opacity: 0.3;
                display: block;
            }
        }

        .mbTopbanner {
            padding: 20px;
            background-size: cover;
            position: relative;

            >* {
                position: relative;
                z-index: 1;
            }
        }
    }

}

.searchWrap {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    @media (min-width: 988px) {
        padding: 28px 0 32px;
    }

    .searchInfo {
        display: flex;
        padding-left: 30px;
        position: relative;

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin: 0;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    @media (max-width: 786px) {
        padding-top: 0;
        margin-top: 20px;
        position: relative;
    }
}

.locateMeBtn {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: 2px;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #0d0d0d;

    &:before {
        width: 14px;
        height: 14px;
        display: inline-block;
        content: "";
        background: url(../dds-images/dds2_location.svg) no-repeat;
        margin-right: 5px;
    }

    @media (max-width: 768px) {
        position: absolute;
        top: 60px;
        left: 12px;
        color: #808080;
        font-size: 9px;
        z-index: 1;
        background-color: #fff;
        margin: 0;
    }

    @media (max-width: 400px) {
        position: absolute;
        top: 60px;
        left: 6px;
        color: #808080;
        font-size: 9px;
        z-index: 1;
        background-color: #fff;
        margin: 0;

        &:before {
            background: url(../img/locateIconGray.svg) no-repeat;
        }
    }
}

.mainSearchWrapper {
    width: 100%;
    position: relative;
    margin-top: -130px;

    @media (min-width: 768px) {
        margin-top: -60px;
    }

    @media (min-width: 1200px) {
        margin-top: 100px;
    }

    // input[type="text"] {
    //     &::placeholder {
    //         font-family: 'Roboto';
    //         font-size: 16px;
    //         font-weight: 500;
    //         line-height: 24px;
    //         letter-spacing: 0.005em;
    //         text-align: left;
    //         color: #6E6E6E;

    //         @media screen and (max-width:408px){
    //             font-size: 12px;
    //         }
    //     }

    //     width: 100%;
    //     background: #fff;
    //     border: 2px solid #B6B6B6;
    //     box-shadow: 0px 2px 2px 0px #002A5814,
    //     0px 1px 1px 0px #002A5814;
    //     height: 55px;
    //     padding: 5px 140px 5px 150px;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 19px;
    //     outline: none;


    //     @media (max-width: 768px) {
    //         padding: 5px 114px 4px 103px;
    //     }

    //     @media (max-width: 786px) {
    //         font-size: 9px;
    //         padding-right: 20px;
    //         width: 100%;
    //         height: 40px;
    //     }

    //     @media (max-width: 400px) {
    //         padding: 5px 54px 5px 94px;
    //     }

    //     @media (max-width: 400px) {
    //         font-size: 9px;
    //         padding-right: 20px;
    //         width: 100%;
    //         height: 40px;
    //     }
    // }

    .searchBtnwrap {
        position: absolute;
        top: 50%;
        display: flex;
        padding: 6px;
        right: 0;
        transform: translateY(-55%);

        a+a {
            margin-left: 6px;
        }

        @media (max-width: 768px) {
            position: absolute;
            justify-content: center;
            margin-top: 46px;
            top: 0;
            right: -2px;

            .gradientBtn {
                width: 64px;
                height: 31px;
                padding: 6px;
            }

            .responsive-label {
                font-size: 10px;
                white-space: nowrap;
            }
        }

        @media (max-width: 400px) {
            position: absolute;
            justify-content: center;
            margin-top: 46px;
            top: 0;
            right: -2px;
        }

    }

    @media (max-width: 786px) {
        margin-top: -93px;
        width: 100%;
        padding: 0 !important;
    }
}

.pr-3 {
    padding-right: 3px !important;
}

.mainSearchWrapper {
    position: relative;
    background: #ffff;
    justify-content: center;
    align-items: center;
    padding: 3px 0 4px 0;
    box-shadow: 0px 2px 2px 0px #002A5814, 0px 1px 1px 0px #002A5814;
    border: 2px solid #B6B6B6;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;

    .mtmInput {
        height: 41px;
        width: 100%;
        font-size: 16px;
        border: none;
        outline: none;
        color: #6E6E6E;
        font-weight: 500;
        // padding-top: 5px;
    }
}



.searchRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;

    &:after {
        content: "";
        position: absolute;
        left: 100%;
        height: 50%;
        width: 1px;
        background: #B6B6B6;
        box-shadow: 0px 2px 2px 0px #002A5814, 0px 1px 1px 0px #002A5814;

    }

    &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        margin-left: 10px;
        width: 22px;
        height: 22px;
        content: "";
        background: url(../dds-images/IconLeft.svg) no-repeat;

        @media (max-width: 768px) {
            left: 10px;
            top: 100%;
            margin-top: 19px;
            margin-left: 76x;
            background-size: 14px 21px;
        }
    }

    label {
        color: #a9a9a9;
        font-size: 14px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        line-height: 50px;
        cursor: pointer;

        &+label {
            margin-left: 15px;
        }

        input[type="radio"] {
            display: none;

            &:checked {
                &+span {
                    color: #000;

                    @media (max-width: 768px) {
                        color: #fff;
                    }

                    &:after {
                        border: 1px solid #000;

                        @media (max-width: 768px) {
                            border: 1px solid #fff;
                        }
                    }

                    &:before {
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }

        span {
            position: relative;
            padding-left: 30px;

            &:after {
                content: "";
                width: 20px;
                height: 20px;
                border: 1px solid #b5b5b5;
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                background: linear-gradient(270deg, #652a80 0%, #bd2d2f 105.51%);
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%) scale(0);
                transition: 0.3s;

                @media (max-width: 768px) {
                    background: #fff;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        height: 50px;
    }

    @media (max-width: 768px) {
        position: relative;

        &::before {
            left: 10px;
            top: 100%;
            margin-top: 19px;
            margin-left: 75px;
            background-size: 16px 21px;
        }

        &::after {
            display: none;
        }
    }

    @media (max-width: 400px) {
        position: relative;

        &::before {
            left: 10px;
            top: 100%;
            margin-top: 19px;
            margin-left: 66px;
            background-size: 16px 21px;
        }

        &::after {
            display: none;
        }
    }
}

.popularCity {
    text-align: center;

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }

    .plList,
    .plListOne {
        display: flex;
        justify-content: center;
        margin: 16px 0;

        @media (max-width: 768px) {
            padding: 0 35px;
            display: block;
        }

        li {
            display: inline-flex;
            padding: 0 15px;

            @media (max-width: 786px) {
                padding: 0 5px;
                width: 105px;
            }

            a {
                color: #0A0E14;
                text-decoration: underline;
                display: inline-flex;
                flex-direction: column;
                font-weight: 500;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.005em;
                text-align: center;

                &:hover {
                    i {
                        transform: translateY(-6px);
                    }
                }
            }

            em {
                width: 94px;
                height: 65px;
                margin-bottom: 9px;
                transform: translateY(0px);
                transition: 0.3s;

                &.mumbai {
                    background: url(../dds-images/mumbai.svg) no-repeat center bottom;
                    background-size: contain;
                }

                &.delhi {
                    background: url(../dds-images/delhi.svg) no-repeat center bottom;
                    background-size: contain;
                }

                &.banglore {
                    background: url(../dds-images/banglore.svg) no-repeat center bottom;
                    background-size: contain;
                }

                &.Hyderabad {
                    background: url(../dds-images/Hyderabad.svg) no-repeat center bottom;
                    background-size: contain;
                }

                &.Chennai {
                    background: url(../dds-images/Chennai.svg) no-repeat center bottom;
                    background-size: contain;
                }

                &.Kolkata {
                    background: url(../dds-images/Kolkata.svg) no-repeat center bottom;
                    background-size: contain;
                }

                @media (max-width: 786px) {
                    opacity: 0.7;
                }
            }

            strong {
                width: 100%;
                display: block;

                @media (max-width: 786px) {
                    color: #000;
                    font-weight: 400;
                }
            }
        }

        &.bigarrow {
            .slick-prev {
                left: 0;
            }

            .slick-next {
                right: 0;
            }
        }
    }
}

.productOffer {
    padding-top: 36px;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
        .container {
            flex-wrap: wrap;
        }
    }

    .poBox {
        width: 48%;
        height: calc(100% - 10px);

        &+.poBox {
            padding-left: 16px;
            padding-right: 0;
        }

        @media (max-width: 1024px) {
            // width: 100%;
            padding: 0px 0 30px 0 !important;

            .newProduct {
                padding-bottom: 25px;
            }
        }

        @media (max-width: 991px) {
            width: 100%;
            padding: 0px 0 30px 0 !important;

            .newProduct {
                padding-bottom: 25px;
            }
        }

        &.pobox30 {
            width: 27.5% !important;

            @media (max-width: 991px) {
                width: 40% !important;
            }
            
            @media (max-width: 768px) {
                width: 100% !important;
            }
            
            &+.pobox30 {
                padding-left: 18px;
            }

            .poCardwrap {
                width: 100%;
                margin: 0;
                height: calc(100% - 10px);

                p {
                    color: #414141;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            @media (max-width: 1024px) {
                width: 50%;
                padding-right: 10px !important;

                &+.pobox30 {
                    padding-left: 10px !important;
                }
            }

            @media (max-width: 800px) {
                width: 100%;
            }
        }

        &.pobox30riev {
            width: 24.5%;

            &+.pobox30riev {
                padding-left: 18px;
            }

            .poCardwrap {
                width: 100%;
                margin: 0;
                // height: 100%;
                height: calc(100% - 10px);

                p {
                    color: #414141;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            @media (max-width: 1024px) {
                width: 60%;
                padding-right: 10px !important;

                &+.pobox30riev {
                    padding-left: 10px !important;
                }
            }

            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }
}

.poInnerBox {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    margin-top: 20px;
    width: 100%;
    height: 338px;
    
    @media (max-width: 600px) {
        flex-flow: unset;
        align-content: unset;
        height: auto;
        justify-content: space-between;
    }

    @media (max-width: 600px) {
        display: block;

        &.mobileSlider {
            width: calc(100% + 20px);
            margin: 20px -10px 0;

            .slick-slide {
                padding: 0 10px;
            }
        }
    }

    .potwoBox {
        width: 49%;
    }

    .poCardwrap {
        width: 49%;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        position: relative;

        &__Fullwidth {
            width: 100%;
            position: relative;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;

            iframe {
                width:100%;
                height:100%;
                // width: 492px;
                // height: 271.85px;
                @media screen and (min-width: 1024px) and (max-width: 1260px) {
                    // width: 350px;
                    // height: 200px; 
                }
                @media screen and (max-width: 495px) {
                    width: 350px;
                    height: 200px; 
                }
            }

            span {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: 135px;
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                padding: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;

                &:after {
                    position: absolute;
                    top: -7px;
                    right: -5px;
                    width: 17px;
                    height: calc(100% + 10px);
                    border: solid 2px #fff;
                    border-left: 0;
                    content: "";
                }

                &:before {
                    position: absolute;
                    top: -7px;
                    left: -5px;
                    width: 17px;
                    height: calc(100% + 10px);
                    border: solid 2px #fff;
                    border-right: 0;
                    content: "";
                }
            }

            img {
                height: 296px;
                object-fit: cover;
                width: 100%;
            }

            @media (max-width: 600px) {
                height: 300px;
            }
        }

        @media (max-width: 1024px) {
            width: 32.33%;

            img {
                height: 100%;
                width: 100%;
            }
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 20px;
            overflow: visible;

            .reviewItem {
                overflow: hidden;
                border-radius: 4px;
            }

            img {
                height: 100%;
                width: 100%;
            }

            &.aceWith {
                img {
                    // height: 150px;
                }
            }
        }

        &:last-child {
            margin-top: clamp(10px, 1.4vw, 19px);

            @media (max-width: 1024px) {
                margin-top: 0px;
            }
        }

        img {
            //width: auto;
            width: 100%;
        }

        span {
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 135px;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            padding: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            &:after {
                position: absolute;
                top: -7px;
                right: -5px;
                width: 17px;
                height: calc(100% + 10px);
                border: solid 2px #fff;
                border-left: 0;
                content: "";
            }

            &:before {
                position: absolute;
                top: -7px;
                left: -5px;
                width: 17px;
                height: calc(100% + 10px);
                border: solid 2px #fff;
                border-right: 0;
                content: "";
            }
        }
    }
}



.poInnerBoxImg {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    margin-top: 20px;
    width: 100%;
    //height: 306px;

    @media (max-width: 600px) {
        flex-flow: unset;
        align-content: unset;
        height: auto;
        justify-content: space-between;
    }

    @media (max-width: 600px) {
        display: block;

        &.mobileSlider {
            width: calc(100% + 20px);
            margin: 20px -10px 0;

            .slick-slide {
                padding: 0 10px;
            }
        }
    }

    .potwoBox {
        width: 49%;
    }

    .poCardwrap {
        width: 49%;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        position: relative;

        &__Fullwidth {
            width: 100%;
            position: relative;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;

            iframe {
                // width: 492px;
                // height: 271.85px;
                width: 100%;
                height: 100%;

            }

            span {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: 135px;
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                padding: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;

                &:after {
                    position: absolute;
                    top: -7px;
                    right: -5px;
                    width: 17px;
                    height: calc(100% + 10px);
                    border: solid 2px #fff;
                    border-left: 0;
                    content: "";
                }

                &:before {
                    position: absolute;
                    top: -7px;
                    left: -5px;
                    width: 17px;
                    height: calc(100% + 10px);
                    border: solid 2px #fff;
                    border-right: 0;
                    content: "";
                }
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        @media (max-width: 1024px) {
            width: 32.33%;

            img {
                height: 100%;
                width: 100%;
            }
        }

        @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 20px;
            overflow: visible;

            .reviewItem {
                overflow: hidden;
                border-radius: 4px;
            }

            img {
                height: 100%;
                width: 100%;
            }

            &.aceWith {
                img {
                    // height: 150px;
                }
            }
        }

        &:last-child {
            margin-top: clamp(10px, 1.4vw, 19px);

            @media (max-width: 1024px) {
                margin-top: 0px;
            }
        }

        img {
            //width: auto;
            width: 100%;
        }

        span {
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 135px;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            padding: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            &:after {
                position: absolute;
                top: -7px;
                right: -5px;
                width: 17px;
                height: calc(100% + 10px);
                border: solid 2px #fff;
                border-left: 0;
                content: "";
            }

            &:before {
                position: absolute;
                top: -7px;
                left: -5px;
                width: 17px;
                height: calc(100% + 10px);
                border: solid 2px #fff;
                border-right: 0;
                content: "";
            }
        }
    }
}

.offerBanner {
    h4 {
        position: absolute;
        top: 50px;
        right: 20px;
        font-weight: 800;
        font-size: 25px;
        line-height: 27px;
        color: #fff;
    }
}

.reviewSlider {
    width: 100%;
    margin: 0 !important;

    .reviewItem {
        line-height: 0;
        position: relative;

        &.offerBanner {
            &:after {
                display: none;
            }

            h4 {
                top: 50px;
                right: 20px;
                bottom: auto;
                left: auto;
            }
        }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100px;
            opacity: 0.5;
            background: linear-gradient(0deg, #000000 30.73%, rgba(196, 196, 196, 0) 100%);
            left: 0;
            bottom: 0;
        }

        h4 {
            position: absolute;
            left: 20px;
            bottom: 10px;
            font-weight: 800;
            font-size: 25px;
            line-height: 27px;
            color: #fff;
            z-index: 11;
        }
    }

    // .slick-slide {
    //     line-height: 0;
    // }

    // .slick-dots {
    //     position: absolute;
    //     bottom: 10px;
    //     right: 10px;
    //     top: auto;
    //     width: auto;
    //     left: auto;

    //     >li button {
    //         background: #fff;
    //     }
    // }
}

.aceContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    h4 {
        font-weight: 800;
        font-size: 25px;
        line-height: 27px;
        color: #fff;
    }

    .aceslider {
        display: flex;
        width: 100%;
        align-self: flex-start;
        justify-content: space-around;
        text-align: center;
        color: #fff;

        li {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        em {
            width: 42px;
            height: 42px;
            display: inline-flex;

            &.instDelivery {
                background: url(../img/shipping.svg) no-repeat center center;
            }

            &.inPerson {
                background: url(../img/user-check.svg) no-repeat center center;
            }

            &.inAccessories {
                background: url(../img/accessoris.svg) no-repeat center center;
            }
        }

        strong {
            font-size: 14px;
            width: 100%;
            font-weight: normal;
        }
    }
}

// .reviewSlider .slick-slide {
//     padding: 0 !important;
// }

.wrapReviewSlider {
    background: #E5F1FA;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        color: #050505;
        margin: 0;
    }

    >p {
        font-weight: 400;
        font-size: 12px;
        line-height: 27px;
        margin: 0;
        /* identical to box height, or 225% */

        color: #414141;
    }

    .starRatingwrap {
        display: flex;
        font-weight: 800;
        font-size: 18px;
        line-height: 1;
        // color: #AF2D3D;
        color: #080808;

        img {
            width: 95px;
            min-width: 95px !important;
            height: auto !important;
            margin-right: 7px;

            @media (max-width:768px) {
                min-width: 190px;
            }
        }
    }

    // .slick-dots {
    //     top: auto;
    //     bottom: -20px;
    //     padding: 5px;

    //     li {
    //         display: inline-flex;
    //     }

    //     &:focus-within {
    //         outline: auto;
    //     }


    // }
}

.reviewSliderNew {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-top: 5px;
    border-top: 1px dashed #AAAAAA;
}

.rsWrap {
    display: block;
    width: 100%;
    margin-top: 10px;

    .rsSlide {
        width: 100%;

        .rsTop {
            display: flex;
            align-items: center;

            .profilePhoto {
                width: 50px;
                border-radius: 50%;
                height: 50px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .rsDetail {
                h4 {
                    margin: 0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #000000;
                    text-transform: capitalize;
                }

                h5 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 12px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 1.3;
                    color: #3B3B3B;

                }
            }

            .cstReview {
                display: flex;
                align-items: center;
                margin-left: auto;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #662A80;

                img {
                    width: 18px;
                    margin-right: 5px;
                    height: auto !important;
                }
            }

        }

        .custrevText {
            margin-top: 15px;

            p {
                font-weight: 400;
                font-size: 14px !important;
                line-height: 18px !important;
                color: #000000 !important;
                font-family: 'Roboto', sans-serif;
            }
        }
    }
}

.mainBanner12 {
    background: url(../img/bannerBgImg.png) no-repeat center center;
    background-size: cover;
    position: relative;

    &.innerPage {
        padding: 13px 16px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(262.84deg, #662980 14.11%, #03437a 87.33%);
        mix-blend-mode: normal;
        opacity: 0.3;
    }

    .container {
        flex-direction: column;
        color: #fff;
        position: relative;
        z-index: 1;
        text-align: center;
        justify-content: center;
    }

    @media (max-width: 786px) {
        background: transparent;
        padding: 0;
        margin-top: -1em;

        &:after {
            display: none;
        }

        &.innerPage {
            background: url(../img/bannerBgImg.png) no-repeat center center;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(262.84deg, #662980 14.11%, #03437a 87.33%);
                mix-blend-mode: normal;
                opacity: 0.3;
                display: block;
            }
        }

        .mbTopbanner {
            padding: 20px;
            background: url(../img/bannerBgImg.png) no-repeat center center;
            background-size: cover;
            position: relative;

            >* {
                position: relative;
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(262.84deg, #662980 14.11%, #03437a 87.33%);
                mix-blend-mode: normal;
                opacity: 0.3;
            }
        }
    }

    @media (max-width: 600px) {
        .mbTopbanner {
            background: url(../img/bannerBgImg.png) no-repeat center right -70vw;
        }
    }
}




.slick-next.slick-arrow:before,
.slick-prev.slick-arrow:before{
    content: '';
    height: 0;
}

@media screen and (max-width:1024px) {

  .toggleOfferDiv {
    padding:5px;
    margin-right : 10px
   } 
   
}
@media screen and (max-width:768px) {
    .productOffer .poBox.pobox30 {
      top: -100px !important;
  }
}
