/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}


ul li::marker {
  color: transparent !important;
}

// .slick-dots li {
//     border: none !important;
// }

.slick-slide.slick-current {
  li {
    border: none !important;
  }
}

.storeList {
  .slick-arrow.slick-prev {
    left: -20px;
  }

  .slick-arrow.slick-next {
    right: -20px;
  }
}


// .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon  {
//     width: 2em !important;
//     position: absolute !important;
//     bottom: -79px !important;
//     right: 40px !important;
// }

.mat-form-field-suffix {
  white-space: nowrap;
  flex: none;
  position: relative;
  top: -36px !important;
}


.mat-select .mat-select-trigger {
  // width: 302px;
  height: 48px !important;
  border-radius: 0px;
  background: #ffffff;
  // border: 1px solid #c2d5d9;
  // height: 63px;
  width: 100%;
  padding: 0 22px;
  font-weight: 500;
  font-size: 14px;
  border: .0625rem solid transparent;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0);
}

.prodselect .mat-select-panel {
  position: absolute;
  top: -278px;
  bottom: 0 !important;
}

.timeselect .mat-select-panel {
  position: absolute;
  top: 0px;
  bottom: 0 !important;
}

.efListwrap li span {
  // position: absolute !important;
  top: 12px !important;
  // height: 100%;
}

// .mat-form-field-hide-placeholder .mat-select-placeholder {
//     color: black !important;
//     -webkit-text-fill-color:black !important;
//     transition: none;
//     display: block;
// }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  width: 257px;
  // top: 14px;
  padding: .4375em 0;
}

input.mat-input-element {
  /* margin-top: 1.9375em; */
  margin-top: -2px;
}

.mat-form-field {
  display: block !important;
  position: relative;
  text-align: left;
}

.custom-dialog-background .cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-select-value-text {
  white-space: nowrap;
  overflow: initial !important;
  text-overflow: ellipsis;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
  transform: none;
  transition: transform 300ms cubic-bezier(0.25, 0.8, 0.25, 1), opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1), background-color 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

#supportchatwidget .rectangular-widget {
  z-index: 999;
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: none;
}

@media screen and (max-width:799px) {
  .mat-aligns .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }


  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width:800px) {
  .mat-select .mat-select-trigger {
    // width: 302px;
    height: 48px !important;
    border-radius: 0px;
    background: #ffffff;
    border: 1px solid #c2d5d9;
    width: 100%;
    padding: 0 22px;
    font-weight: 500;
    font-size: 14px;

  }


}

#widget-open {
  display: none !important;
}

#banner h3 {
  font-size: 18px;
  letter-spacing: .2em;
  text-decoration: underline;
}

#banner h1 {
  font-size: 42px;
  line-height: 1.6em;
  font-weight: 700;
}

#banner p {
  line-height: 26px;
  font-size: 20px;
}

#banner {
  background-size: contain;
  // margin-top: 0.5em;
}

@media screen and (min-width: 800px) {
  #banner {
    // padding: 3% 0;
  }
}

@media screen and (min-width: 940px) {
  #banner {
    padding: 0;
    margin-top: 0;
    z-index: 1;
  }
}

@media screen and (min-width: 1080px) {
  #banner {
    margin-top: 0;
    padding: 0;
    z-index: 1;
  }
}

@media screen and (min-width: 1279px) {
  #banner {
    // margin-top: -5em;
    background-size: 100% auto;
    // padding: 5% 0;
    margin-bottom: -4em;


  }
}

.banner-img1 {
  position: relative;
}

.banner-img1 img {
  width: 100%;
  height: auto;
}

.sliderParent {
  position: relative;
}


.lcHead {
  h2 {
    a {
      text-decoration: none !important;
    }
  }
}

.f-32 {
  font-size: 32px !important;
}

.bg-trans {
  background: transparent !important;
  font-size: 16px !important;

}

.error {
  font-size: .75rem;
  letter-spacing: .005em;
  line-height: 1.25rem;
  font-weight: 400;
  color: #bb2a33;
  bottom: -16px;
  position: absolute;
}

.consent-error {
  color: #bb2a33;
}


#slider_dots,
#slider_dots-gr {
  margin: 7px auto;
  width: 200px;
}


.storeList .slick-arrow.slick-prev {
  left: 38%;
  top: 106.4%;
  position: absolute;

  @media screen and (max-width:700px) {
    left: 12%;
    top: 105.6%;
  }
}

.storeList .slick-arrow.slick-next {
  right: 39%;
  top: 107.4%;
  position: absolute;

  @media screen and (max-width:700px) {
    right: 16%;
    top: 108.4%;

  }
}


// .slick-dots {
//   display: flex;
//   justify-content: center;
//   margin: 0;
//   padding: 1rem 0;
//   list-style-type: none;
// }

// .slick-dots li {
//   margin: 0 0.25rem;
// }

// .slick-dots button {
//   display: block;
//   width: 1rem;
//   height: 1rem;
//   padding: 0;
//   border: none;
//   border-radius: 100%;
//   background-color: grey;
//   text-indent: -9999px;
// }


.slider_navigators,
.slider_navigators2 {
  background-color: #000;
  border-radius: 4px;
  margin: 10px;
  transform: scale(1);
  outline: none;
  cursor: pointer;
  height: 8px;
  width: 8px !important;
}


#slider_dots .slick-current.slick-active {

  .slider_navigators,
  .slider_navigators2 {
    width: 100% !important;
    display: inline-block;
    transform: translateX(-44%) !important;
    left: 50%;
  }
}

#slider_dots-gr .slick-current.slick-active {

  .slider_navigators,
  .slider_navigators2 {
    width: 100% !important;
    display: inline-block;
    transform: translateX(-44%) !important;
    left: 50%;
  }
}



.grList .slick-arrow.slick-prev {
  position: absolute;
  left: 43%;
  top: 116.4%;

  @media screen and (max-width: 700px) {
    left: 25%;
  }
}

.grList .slick-arrow.slick-next {
  right: 42%;
  top: 118.5%;
  position: absolute;

  @media screen and (max-width: 700px) {
    right: 26%;
  }
}

.pgListToggle .slick-current.slick-active li img {
  border: 4px solid #0972cb;
}

.pgCoverImage .pgItem img {
  object-fit: cover !important;
}

#thumbnail-slider {
  position: relative;
  left: 50%;
  transform: translateX(-53%);
}

#main-slider .slick-arrow.slick-next {
  right: -35px;
  top: 120%;
}

#main-slider .slick-arrow.slick-prev {
  left: -35px;
  top: 115%;
}

.dds__button--secondary:not(:disabled):not(.dds__inactive):hover,
.user-is-tabbing .dds__button--secondary:focus {
  background-color: #d9f5fd;
  color: #0972cb !important;
}

.mt-10 {
  margin-top: 6rem;
}

.mat-select-value {
  transform: translateY(-8px) !important;
}

.clear-btn {
  background: transparent;
  border: none;
}

.clear-btn:focus-visible {
  outline: 1px solid #00468b;
}

.navbar-toggle:focus-visible {
  background: #f0f0f0;
  border-bottom: 1px solid #00468b;
}


.fas.fa-pause,
.fas.fa-play {
  font-size: 20px;
}

.fas.fa-pause:focus,
.fas.fa-play:focus,
.loadMoreBtnWrap .loadMoreBtn:focus,
button:focus,
a:focus,
.pageHead:focus,
#scroll:focus-visible,
#dell-search:focus-visible,
#googleMapWrapper:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #00468b;
}


#toggleAutoplay {
  z-index: 998;
  // z-index: 1000;
  transition: all .1s ease;
}



.carousel-container {
  .slick-dots {
    margin-top: -75px !important;

    li {
      button {
        background-color: #fff !important;
      }

    }

  }

  .slick-arrow.slick-prev {
    top: 92% !important;
    left: 35% !important;
  }

  .slick-arrow.slick-next {
    top: 92% !important;
    right: 35% !important;
  }
}
@media screen and (min-width: 340px) and (max-width:374px){
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 74% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 74% !important;
      right: 30% !important;
    }
  }
}
@media screen and (min-width: 375px) and (max-width:415px){
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 79% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 78% !important;
      right: 30% !important;
    }
  }
}
@media screen and (min-width: 416px) and (max-width: 539px) {
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 77% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 76% !important;
      right: 30% !important;
    }
  }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 83% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 83% !important;
      right: 30% !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 819px) {
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 89% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 89% !important;
      right: 30% !important;
    }
  }

}
@media screen and (min-width: 820px) and (max-width: 1023px) {
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 86% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 86% !important;
      right: 30% !important;
    }
  }

}
@media screen and (min-width: 1024px) and (max-width: 1261px){
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 89% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 89% !important;
      right: 30% !important;
    }
  }

}
@media screen and (min-width: 1262px) and (max-width: 1265px){
  .carousel-container {
    .slick-arrow.slick-prev {
      top: 91% !important;
      left: 30% !important;
    }

    .slick-arrow.slick-next {
      top: 91% !important;
      right: 30% !important;
    }
  }

}




/* Skeleton loader styles */
.skeleton-loader {
  width: 100%; /* Adjust width as needed */
  height: 100%; /* Adjust height as needed */
  min-height: 338px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 8px; /* Rounded corners */
  animation: shimmer 1.5s infinite;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Shimmer effect */
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
