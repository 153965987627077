@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("./swiper-bundle.min.css");

body {
    &.scrollHide {
        overflow: hidden;
    }
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    //outline: none !important;
    margin: 0;
    padding: 0;
}

ul,
li {
    // list-style-type: none;
    margin: 0;
}

img {
    max-width: 100%;
}

body {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: no-common-ligatures;
    font-variant-ligatures: no-common-ligatures;
    padding-top: 70px;
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    padding: 0 15px;
}

// @media (min-width: 1025px)  and (max-width: 1400px){
//     .container {
//         padding: 0 60px;
//     }
// }

.mainHeader {
    width: 100%;
    display: flex;
    background: #0076ce;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;

    // .container{
    //     max-width: 1170px;
    // }
    .navbar-toggle {
        display: none;

        @media (max-width: 786px) {
            padding: 15px 15px 15px 20px;
            display: inline-flex;
            background: transparent;
            border: 0;
            margin-right: 0px;

            em {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 14px;
                width: 22px;
                position: relative;

                span {
                    background: #fff;
                    width: 22px;
                    height: 2px;
                    border-radius: 2px;
                    display: block;
                    position: absolute;
                    transition: 0.3s;

                    &:first-child {
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:nth-child(2) {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:last-child {
                        width: 11px;
                        top: 100%;
                        left: 0%;
                        transform: translateY(-50%);
                    }
                }
            }

            &.active {
                em {
                    span {
                        &:first-child {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                        }

                        &:nth-child(2) {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }

                        &:last-child {
                            width: 11px;
                            top: 50%;
                            left: 0%;
                            transform: translateY(-50%);
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .container {
        align-items: center;

        @media (max-width: 786px) {
            padding: 0;
        }
    }

    .headerLogo {
        line-height: 0;
        width: 46px;
        display: block;

        img {
            width: 100%;
        }
    }

    @media (min-width:786px) {
        .navbar ul {}

        .mainHeader {
            box-shadow: #000;
        }
    }

    .navbar {
        display: inline-flex;
        width: 250px !important;

        ul {
            display: inline-flex;
            margin-left: 57px;
            padding: 0px 0px !important;
            width: fit-content;

            li {
                display: inline-flex;
                // border-bottom: 1px #fff solid;

                // &:first-child {
                //     display: none;
                // }
                a {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    font-size: 1em;
                    padding: 30px 14px 8px;
                    text-decoration: none;
                    position: relative;

                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: #fff;
                        content: "";
                        transform: scale(0, 1);
                        transition: 0.3s;
                        translate: 0.3s;
                    }

                    &:hover {
                        &:after {
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }

        .dropdown-menu li {
            border: none;

        }

        @media (min-width:786px) {
            .dropdown-menu li {
                border-bottom: 1px solid #3ca0e7;
                font-size: 14px;
            }
        }

        @media (max-width: 1024px) {
            ul {
                margin-left: 20px;

                li a {
                    font-size: 14px;
                    padding: 35px 5px 16px;
                }
            }

        }


        @media (max-width: 786px) {
            display: flow-root;
            position: fixed;
            height: calc(100vh - 67px);
            width: 315px;
            max-width: 90%;
            background: #0076ce;
            top: 80px;
            left: 0;
            z-index: 11;
            transform: translate(-100%);
            transition: 0.3s;

            &.showNavbar {
                transform: translate(0%);
            }

            ul {
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                width: 100%;
                margin: 0;

                li {
                    // border-bottom: 1px solid #c4c4c4;

                    // &:first-child {
                    //     display: inline-flex;
                    //     background: #fff;
                    //     width: calc(100% + 20px);
                    //     margin-left: -10px;
                    //     padding-left: 10px;
                    //     padding-right: 10px;
                    //     a {
                    //         align-items: center;
                    //         display: flex;
                    //         padding: 23px 0;
                    //         width: 100%;
                    //     }
                    // }
                    // &:first-child,
                    // &:last-child {
                    //     border: 0;
                    // }
                    a {
                        color: #fff;
                        padding: 30px 0;
                        font-weight: 400;
                        font-size: 1.2em
                    }
                }
            }
        }
    }

    @media (max-width: 786px) {
        padding: 10.5px 0;
    }
}

.userIcon {
    width: 30px;
    height: 30px;
    background: url(../img/userIcon.svg) no-repeat;
    display: inline-flex;
    background-size: 29px;
    margin-right: 5px;
}

.menuOverly {
    display: none;
}

@media (max-width: 786px) {
    .menuOverly {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
        transform: translate(-100%);
        transition: 0.3s;

        &.active {
            transform: translate(0%);
        }
    }

    .mobileP0 {
        padding: 0;
    }
}

.socialContact {
    display: inline-flex;
    margin-left: auto;

    >span {
        display: inline-flex;
        flex-direction: column;
        padding: 0 26px;

        @media (max-width: 1024px) {
            padding: 0 16px;
        }

        &+span {
            border-left: solid 1px #fff;

            @media (max-width: 786px) {
                display: none;
            }
        }

        small {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #ffffff;
            margin-bottom: 4px;

            @media (max-width: 786px) {
                margin-bottom: 0px;
            }

            i {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 0;
                    height: 0;
                    border-top: 4px solid transparent;
                    border-left: 5px solid #fff;
                    border-bottom: 4px solid transparent;
                }
            }
        }

        .ccNumber {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
            text-decoration: none;


            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        &:hover small i:after {
            animation: bounceRight 2s infinite;
        }
    }
}

.socialList {
    display: inline-flex;
    padding-left: 0px;

    li {
        display: inline-flex;

        &:first-child a {
            padding-left: 0;
        }

        a {
            display: inline-flex;
            padding: 5px;
            transition: transform .3s ease-in-out;

            &:hover {
                transform: translateY(-3px);
            }
        }
    }

    em {
        width: 22px;
        height: 16px;
        display: block;

        &.fbIcon {
            background: url(../img/facebookIcon.svg) no-repeat center;
        }

        &.twIcon {
            background: url(../img/twt.svg) no-repeat center;
            background-size: cover;
        }

        &.isIcon {
            background: url(../img/insta.svg) no-repeat center;
        }

        &.ytIcon {
            background: url(../img/youTube.svg) no-repeat center;
        }

        &.ldIcon {
            background: url(../img/linkedin.svg) no-repeat center;
        }
    }
}

@keyframes bounceRight {

    0%,
    50%,
    100% {
        transform: translate(0, -50%);
    }

    25% {
        transform: translate(5px, -50%);
    }

    75% {
        transform: translate(5px, -50%);
    }
}

.gradientBtn {
    //background: linear-gradient(270deg, #652a80 0%, #bd2d2f 105.51%);
    background: linear-gradient(270deg, #bd2d2f 0%, #652a80 40%, #652a80 60%, #bd2d2f 100%);
    border-radius: 15px 15px 15px 0px;
    padding: 13px 16px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    background-size: 300% 100%;
    transition: 0.4s;
    margin: 0;

    &:hover {
        background-position: 100% 0;
        color: bisque;
    }

    &:disabled {
        background-color: #616161 !important;
    }
}

.grayBtn {
    background: #c8c9c7;
    border-radius: 15px 15px 15px 0px;
    padding: 13px 16px;
    color: #444444;
    font-size: 14px;
    text-decoration: none;
    transition: 0.4s;

    &:hover {
        background-position: 100% 0;
    }
}

.yellowBtn {
    background: linear-gradient(270deg, #bd2d2f 0%, #652a80 40%, #652a80 60%, #bd2d2f 100%);
    background-size: 300% 100%;
    border-radius: 15px 15px 15px 0px;
    padding: 13px 16px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    transition: 0.4s;
    font-weight: 700;
    align-items: center;
    display: inline-flex;
    cursor: pointer;

    &.large {
        font-size: 20px;
        line-height: 23px;
        padding: 20px 52px;
        border-radius: 20px 20px 20px 0;

        // &:hover {}
        @media (max-width: 786px) {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            padding: 15px 30px;
            border-radius: 10px 10px 10px 0;
        }
    }

    &:hover {
        color: #fff;
    }
}

h1.pageHead {
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    display: inline-flex;
    padding-right: 50px;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 14px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 80px;
        background: #000;
    }

    strong {
        margin: 0 5px;
        // background: linear-gradient(262.84deg, #b82d35 14.11%, #03437a 87.33%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // -moz-background-clip: text;
        // -moz-text-fill-color: transparent;
        color: #00468B;
    }

    @media (max-width: 786px) {
        font-size: 21px;
        line-height: 25px;
        padding-bottom: 10px;
    }

    @media (max-width: 786px) {
        font-size: 15px;
    }
}

.listingPage {
    background: #f5f7fe;
}

.loadMoreBtnWrap {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 40px 0;
    cursor: pointer;

    .loadMoreBtn {
        display: inline-flex;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        position: relative;
        background-color: transparent;
        border: 0;

        &:after {
            content: "";
            width: 28px;
            height: 28px;
            background: url(../dds-images/dds2_chevron-cir-down.svg) no-repeat center center;
            border-radius: 50%;
            margin-left: 5px;
            height: 100%;
        }

        @media (max-width: 786px) {
            font-size: 19px;
            line-height: 21px;

            &:after {
                width: 21px;
                height: 21px;
                background-size: 11px;
            }
        }
    }
}

.backBtnwrap {
    display: flex;
    width: 100%;
    padding: 28px 0;

    .backBtn {
        display: inline-flex;
        position: relative;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #6E6E6E;

        // &:before {
        //     content: "";
        //     width: 28px;
        //     height: 28px;
        //     background: url(../dds-images/Icon-Color.svg) no-repeat center center;
        //     border-radius: 50%;
        //     margin-right: 5px;
        // }

        @media (max-width: 786px) {
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;

            &:before {
                width: 21px;
                height: 21px;
                background-size: 10px;
            }
        }
    }
}

h2.boldLIght {
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    display: inline-flex;
    padding-right: 50px;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 14px;
    position: relative;
    // min-width: 280px;
    color: #00468B;

    @media (max-width: 786px) {
        font-size: 21px;
        line-height: 25px;
        padding-bottom: 10px;
        padding-right: 25px;
        min-width: 0;
    }

    &.towLineHead {
        flex-direction: column;
        color: #808080;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        // width: 80px;
        width: 40%;
        background: #000;
    }

    strong {
        margin-right: 5px;
        display: block;
        position: relative;
        font-weight: 300;
        margin-bottom: 5px;

        span {

            color: #00468B;
            // background: linear-gradient(262.84deg, #b82d35 14.11%, #03437a 87.33%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // -moz-background-clip: text;
            // -moz-text-fill-color: transparent;
        }
    }
}

h5.boldLIght {
    font-weight: 300;
    font-size: 17px;
    line-height: 27px;
    display: inline-flex;
    padding-right: 50px;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 6px;
    position: relative;
    min-width: 280px;
    color: #000;

    @media (max-width: 786px) {
        font-size: 21px;
        line-height: 25px;
        padding-bottom: 10px;
        padding-right: 25px;
        min-width: 0;
    }

    &.towLineHead {
        flex-direction: column;
        color: #808080;
        font-family: 'Roboto', sans-serif;
        font-size: 16px !important;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;

    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 80px;
        background: #000;
    }

    strong {
        margin-right: 5px;
    }

    strong {
        margin-right: 5px;
        display: block;
        position: relative;
        font-weight: bold;
        font-size: 22px;

        span {
            background: linear-gradient(262.84deg, #b82d35 14.11%, #03437a 87.33%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
        }
    }
}

.footerwrap {
    background: #eeeeee;

    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    overflow: hidden;

    .leftText {
        padding: 21px 50px 21px 0;

        @media (max-width: 1024px) {
            padding: 15px;
        }
    }

    .footerLogo {
        background: #dddddd;
        width: 82px;
        padding: 3px 10px 0 23px;
        display: inline-flex;
        align-items: center;
        line-height: 0;
        position: relative;
        font-size: 0;

        &::after {
            content: "";
            height: 100%;
            width: 100vw;
            background: #dddddd;
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
        }

        img {
            width: 49px;
            min-width: 49px;
        }

        @media (max-width: 1024px) {
            width: 100%;
            padding: 10px 15px;
            font-size: 14px;
            text-decoration: none;
            color: #000;

            img {
                margin-right: 10px;
                width: 32px;
                min-width: 32px;
            }

            &:after {
                display: none;
            }
        }
    }

    @media (max-width: 1024px) {
        overflow: visible;

        .container {
            flex-wrap: wrap;
            padding: 0;
        }
    }
}

@media (max-width: 1024px) {
    .swiper {
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
    }
}

.spmItems {
    width: 296px;

    img {
        width: 100%;
    }
}

.slick-slider {
    margin-bottom: 20px;
}

.slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    padding-left: 0;

    >li {
        width: auto !important;
        padding: 0 5px;

        button {
            text-indent: -99999px;
            overflow: hidden;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            // background: rgba(0, 0, 0, 0.25);
            background: #080808;
            border: 0 !important;
            transition: 0.3s;
            outline: 0 !important;
        }

        &.slick-active {
            button {
                width: 33px;
            }
        }
    }
}

.reviewSliderNew .slick-dots{
    top: 72%;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: transparent;
    background: url(../dds-images/right-white.svg) no-repeat;
    border: 0;
    //text-indent: -99999px;
    font-size: 0;
    z-index: 1;

    &.slick-disabled {
        opacity: 0.5;
    }

    &.slick-prev {
        left: 10px;
        transform: translateY(-50%) rotate(180deg);
    }

    &.slick-next {
        right: 10px;
        transform: translateY(-20%);
    }
}
.storeList ,.grList{
    .slick-arrow {
        background: url(../dds-images/right.svg) no-repeat;
    }

}

.mainBanner {
    .slick-arrow {
        background: url(../dds-images/right-white.svg) no-repeat;

        &.slick-prev {
            left: -8px;
        }

        &.slick-next {
            right: -8px;
            top: 52%;

        }
    }
}

.bigarrow {
    .slick-arrow {
        // width: 31px;
        // height: 61px;
        // background: url(../img/arrowBig.svg) no-repeat;

        &:before {
            position: absolute;
            top: 8px;
            right: 0;
            height: 49px;
            width: 3px;
            background: #fff;
            box-shadow: inset 3px -3px 8px rgba(0, 0, 0, 0.25) !important;
            content: "";
        }
    }
}

.slListing.bigarrow {
    .slick-arrow.slick-prev {
        left: 0;

    }

    .slick-arrow.slick-next {
        right: 0;
        top: 52%;
    }
}

.footerMenu {
    background: #3274C8;
    margin-top: 50px;

    .container {
        >ul {
            display: flex;
            padding: 0;

            li {
                position: relative;

                a {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 23px 10px 16px;
                    display: block;
                    color: #FFFFFF;
                    text-decoration: none;

                    &:hover,
                    &:active,
                    &:focus {
                        color: #fff;
                    }

                    &:focus {
                        &+ul {
                            opacity: 1;
                            visibility: visible;
                            bottom: 100%;
                        }
                    }
                }

                ul {
                    display: flex;
                    position: absolute;
                    bottom: 120%;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #FFFFFF;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px 10px 0px 0px;
                    padding: 0px 10px;
                    flex-direction: column;
                    width: 252px;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.3s;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        margin-left: -7px;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-top: 12px solid #DDDDDD;
                    }

                    li {
                        a {
                            padding: 13px 25px 13px 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;

                            color: #000000;
                            background: url('../img/gotoicon.svg') no-repeat center right 5px;

                            strong {
                                display: block;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                            }

                            &:hover {
                                color: #0076ce;
                            }
                        }

                        &+li a {
                            border-top: 1px solid #BABABA
                        }
                    }
                }

                &:hover {
                    ul {
                        opacity: 1;
                        visibility: visible;
                        bottom: 100%;
                    }

                    @media (max-width:600px) {
                        ul {
                            opacity: 0;
                            visibility: visible;
                            bottom: none;
                        }
                    }
                }
            }

            @media (max-width:600px) {
                flex-direction: column;
                width: 100%;

                li {
                    a {
                        padding: 10px 0;
                    }

                    &+li {
                        border-top: solid 1px rgba(255, 255, 255, 0.2);
                    }

                    ul {
                        position: static;
                        opacity: 1;
                        visibility: visible;
                        transform: translate(0, 0);
                        background: transparent;
                        width: 100%;
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                        padding: 0 10px;
                        box-shadow: none;

                        &.active {
                            max-height: 500px;
                            opacity: 1;
                            padding: 0 0;
                        }

                        li {
                            a {
                                color: #fff;

                                strong {
                                    font-size: 14px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

}


.poBox {

    .boldLIght {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 400;
        // line-height: 36px;
        line-height: 5px;
        text-align: left;
        color: #0A0E14;
       font-weight: 500;
        strong {
            font-weight: 400;
            padding-left: 8px;
        }
    }
}