.storeListingWrapper {
    .container {
        flex-wrap: wrap;
    }
}

.slHead {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0 0 0;
    margin-top: -24px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: -77px;
    }

    @media (max-width: 400px) {
        flex-wrap: wrap;
        margin-top: -82px;
    }
}

.slHeadRight {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    p {
        font-size: 14px;
        text-align: right;
        margin-right: 16px;

        strong {
            display: block;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
        margin-top: 23px;

        p {
            text-align: left;
        }

        .yellowBtn {
            background: transparent;
            color: #000000;
            text-decoration: underline;
            padding: 0;
        }
    }
}

.slWrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    @media (max-width: 1024px) {
        padding-bottom: 30px;
    }
}

.googleMapWrapper {
    width: 100%;
    margin-bottom: 30px;

    >google-map {
        width: 100%;
        height: 80vh;
    }
}

.slListing {
    display: flex;
    flex-wrap: wrap;
    width: 101%;
    margin-left: -0.5%;
    position: relative;
    padding-left: 0;


    @media (max-width: 1024px) {
        display: block;
        margin: 0 -15px;
        width: calc(100% + 30px);
        padding: 0 40px;

        .slick-slide {
            padding: 10px;
        }

        .listingCard {
            width: 250px;
        }
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
        gap: 10px;
    }
}

  
.listingCard {
    display: inline-flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px 10px;
    border-radius: 2px;
    width: 24%;
    margin: 0 0.5% 20px;
    position: relative;
    transform: translateY(0px);
    transition: 0.2s;
    box-shadow: 0px 2px 2px 0px #002A5814, 0px 1px 1px 0px #002A5814;

    @media (max-width: 1024px) {
        width: 296px;
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
        width: 30%;
        // gap: 10%;
    }

    &::after,
    &::before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background: #f5f7fe;
        position: absolute;
    }

    &::after {
        right: -10px;
        bottom: 24.5%;
    }

    &::before {
        left: -10px;
        bottom: 24.5%;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.2);
        z-index: 1;

        &::before {
            // box-shadow: inset -10px 0 5px rgba(0, 0, 0, 0.2);
        }
        &::after {
            // box-shadow: inset 10px 0 5px  rgba(0, 0, 0, 0.2);
        }
    }

    .botn {
        font-size: 14px;
        display: flex;
        justify-content: center;
        width: 90%;
        margin: auto;

        &:hover {
            color: #fff !important;
        }
    }
}


.listingCard::before:hover {
    box-shadow: inset -7px 0 8px rgba(0, 0, 0, 0.1);
}

.listingCard::after:hover{
    box-shadow: inset -7px 0 8px rgba(0, 0, 0, 0.1);
    }

.storeDistance {
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    span {
        font-weight: normal;
        color: #444;
    }

    .sharewrap {
        margin-left: auto;
    }

    em {
        width: 19px;
        height: 19px;
        margin-right: 5px;
        display: inline-flex;

        &.sdIcon {
            background: url(../img/directions.svg) no-repeat;

            &.flag {
                background: url(../img/flag.svg) no-repeat;
            }
        }
    }
}

.lcHead {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    h2 {
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        color: #7F234F;

        a {
            text-decoration: none;
            color: #7F234F;
            font-weight: 500;
            
        }

        small {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: block;
            color: #7F234F;
            margin-top: 5px;
            min-height: 24px;
            max-height: 33px;
        }
    }
}

.starRating {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #7F234F;
    position: relative;
    align-self: flex-start;
    padding-left: 25px;
    font-size: 16px;
    line-height: 19px;

    &:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: url(../img/redStar.svg) no-repeat;
    }
}

.storeAdress {
    width: 100%;
    margin-bottom: 7px;
    min-height: 95px;

    h3 {
        font-weight: 800;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 10px;
        color: #6e2585;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #444444;
    }


}

.sdListItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 21px;
    padding: 0 !important;
    border-bottom: 1px dashed #cecece;

    li {
        display: inline-flex;
        width: 100%;

        &+li {
            border-top: 1px solid #dddddd;
        }

        abbr {
            padding: 14px 0;
            width: 100%;
            display: flex;
            justify-content: space-between;

            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #7F234F;
            }

            em {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                font-style: normal;
                color: #7F234F;
            }
        }

        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-decoration: none;
            color: #808080;
            padding: 14px 0;
            position: relative;
            width: 100%;
            transition: 0.3s;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 20px;
                height: 100%;
                transform: translateY(-50%);
            }

            &.call:after {
                background: url(../dds-images/dds2_phone.svg) no-repeat center center;
            }

            &.getDirection:after {
                background: url(../dds-images/dds2_location-1.svg) no-repeat center center;
            }
            &.getDirection-new:after {
                background: url(../dds-images/dds2_location_2.svg) no-repeat center center;
            }

            &.websiteLink:after {
                background: url(../dds-images/dds2_chain-link.svg) no-repeat center center;
            }

            &.email:after {
                background: url(../dds-images/Vector_1.svg) no-repeat center center;
            }

            &.clock:after {
                background: url(../dds-images/Vector_2.svg) no-repeat center center;
            }
        }
    }

    &.iconLeft {
        border: 0;

        li {
            a {
                padding-left: 30px;
                text-decoration: underline;
                align-items: center;

                span {
                    display: inline-flex;
                }

                i {
                    display: block;
                    width: 16px;
                    margin-left: 10px;
                    height: 16px;
                    display: inline-flex;
                    align-self: center;
                    background: url(../img/infoicon.svg);
                }

                &:after {
                    right: auto;
                    left: 0;
                }

                em {
                    font-style: normal;
                    color: #616161;
                    font-weight: bold;
                }

                &.clock {
                    text-decoration: none;

                    span {
                        font-family: 'Roboto';
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        text-align: left;
                        color: #808080;

                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .loadmoreListing {
        display: none;
    }
}

.infoDetailwraps {
    position: relative;
    font-style: normal;

    .businessDetailDd {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        position: absolute;
        left: 100%;
        top: -7px;
        margin-left: 15px;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 175px;
        display: flex;
        flex-direction: column;
        padding: 0;

        &::after {
            position: absolute;
            top: 10px;
            right: 100%;
            border-top: 5px solid transparent;
            content: "";
            border-right: 16px solid #fff;
            margin-right: -2px;
            border-bottom: 0px solid transparent;
            transform: rotate(-25deg);
        }

        &::before {
            position: absolute;
            top: 10px;
            right: 100%;
            border-top: 6px solid transparent;
            content: "";
            border-right: 16px solid #ccc;
            margin-right: -1px;
            border-bottom: 0px solid transparent;
            transform: rotate(-25deg);
        }

        .bdinnerwrap {
            overflow: hidden;
            display: block;
            padding: 10px;
        }

        >strong {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 21px;
            color: #000000;

            &.noteHead {
                color: #777;
            }
        }

        ul {
            padding: 0 10px;
            margin: 0 -10px;
            background: #fff;
            margin-bottom: 10px;
            position: relative;
            z-index: 1;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

            // &:before{content: ""; position: absolute; left: 50%; width: 94%; height: 1px; border-radius: 50%; box-shadow:0 4px 4px 4px rgba(0, 0, 0, 0.2) ; content: ""; top: 100%; transform: translate( -50%, -2px); z-index: -1;}
            li {
                display: inline-flex;
                width: 100%;
                justify-content: space-between;
                background: #fff;
                position: relative;
                z-index: 1;

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 21px;
                    /* identical to box height, or 210% */
                    color: #727272;
                }

                strong {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 21px;
                    /* identical to box height, or 210% */
                    color: #727272;
                }
            }
        }

        @media (max-width:800px) {
            top: 100%;
            right: 0;
            left: auto;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        /* or 133% */
        color: #727272;
    }

    &:hover {
        .businessDetailDd {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media (max-width:600px) {
    .mobileNiHidemo {
        display: none !important;
    }
}